import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useToast,
} from "@chakra-ui/react"
import { WindowLocation } from "@reach/router"
import { navigate } from "gatsby"
import React, { useCallback, useContext, useState } from "react"
import { useForm } from "react-hook-form"
import SimpleLayout from "../components/simpleLayout"
import UserContext from "../context/userContext"
import { api } from "../settings"
import queryString from "query-string"

function ForgetReset() {
  const { state, dispatch } = useContext(UserContext)
  const parsed = typeof location !== "undefined" && queryString.parse(location.search)
  const kioskId = parsed && parsed?.id
  const {
    register,
    handleSubmit,

    errors,
  } = useForm({})

  const toaster = useToast()

  const [isLoading, setIsLoading] = useState(false)
  const login = useCallback(
    (x: any) => {
      setIsLoading(true)

      fetch(api + "/api/UserForget", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.x
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          "Content-Type": "application/json",

          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "error", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          email: x.email,
          kioskId
        }), // body data type must match "Content-Type" header
      })
        .then(a => a.json())
        .catch(x => alert("Helaas, onze website werkt (even) niet."))
        .then(a => {
          toaster({
            title: "Wachtwoord link is onderweg.",
            description: "Alleen als u al een account heeft op Fairsy.nl.",
            status: "success",
            duration: 1000 * 1000,
            isClosable: true,
          })
          navigate("/")
        })
        .finally(() => setIsLoading(false))
    },
    [state, dispatch]
  )

  return (
    <Flex width="full" align="center" justifyContent="center">
      <Box p={2}>
        <Box textAlign="center">
          <Heading>{kioskId ? "Krijg toegang tot het kraampje" : "Reset uw wachtwoord"}</Heading>
        </Box>
        <Box my={4} textAlign="left">
          <form onSubmit={handleSubmit(login)}>
            <FormControl mt={6} isInvalid={errors.email != undefined}>
              <FormLabel>E-mailadres</FormLabel>
              <Input
                type="email"
                placeholder=""
                name="email"
                ref={register()}
                isRequired
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>

            <Button width="full" mt={4} type="submit" isLoading={isLoading}>
              {kioskId ? "Verstuur de uitnodiging" : "Verstuur nieuw wachtwoord"}
            </Button>
          </form>
        </Box>

        <div>
          Hulp nodig? profiel wijzigen of vers nieuws delen?
          <br /> Stuur een e-mail naar{" "}
          <a href="mailto:hi@fairsy.nl">hi@fairsy.nl</a>
        </div>
      </Box>
    </Flex>
  )
}

export default function Ee({ location }: { location: WindowLocation }) {
  return (
    <SimpleLayout title="Login" location={location}>
      <ForgetReset />
    </SimpleLayout>
  )
}
